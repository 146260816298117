import React from 'react'
import './notfound.css'

const Notfound = () => {
    return (

        <p>Not Found</p>
    )
}
export default Notfound
