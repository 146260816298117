import React from 'react'

const CTA= () => {
    return (
        <header>
            <div className="cta">
                <a href="#contact" className='btn btn-primary'>Hablemos</a>
            </div>
        </header>
    )

}
export default CTA
